// Root URL
export const root = process.env.PUBLIC_URL;

// Root URL
export const RootUrl = process.env.NODE_ENV === 'production' ? "https://www.britishsyriansociety.org" : "https://localhost:44307"

// API URL
export const APIUrl = `${RootUrl}/api`;

// Menu Items Types
const menuItemTypes = ["Menu", "Page", "Section"];
export const isMenuItemFromType = (itemType, type) => {
    return itemType === menuItemTypes.indexOf(type) + 1;
}

// Attachments Types
export const attachmentsTypes = [
    {
        id: 1,
        title: "Image",
        formats: ".jpg,.jpeg,.gif,.png"
    },
    {
        id: 2,
        title: "File",
        formats: ".pdf,.doc,.docx,.xls,.xlsx,ppt,pptx"
    },
    {
        id: 3,
        title: "Video",
        formats: ".mp4"
    },
];

// Directors Positions
export const directorsPositions = [
    "Chairman",
    "Co-Chairman",
    "Director",
    "Founder & Co-Chairman"
];

// Directors Status
export const directorsStatus = [
    "Past",
    "Current"
];

// Intro Page
export const introPage = "british-syrian-society";

// Filters
export const filterDates = [
    "All",
    "Past",
    "Upcoming"
];


// Pagers
export const homeEvents = 4;
export const eventsList = 20;