import React, { Suspense, lazy, useEffect } from 'react';
import LoaderImage from './components/LoaderImage';
import Error from './components/Error';
import Template from './components/Template';

// https://reacttraining.com/blog/react-router-v5-1/
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// code splitting: https://reactjs.org/docs/code-splitting.html
const Home = lazy(() => import('./components/Home'));
const Page = lazy(() => import('./components/Page'));
const Event = lazy(() => import('./components/Event'));
const Events = lazy(() => import('./components/Events'));
const EventRegistrationConfirmation = lazy(() => import('./components/EventRegistrationConfirmation'));
const Directors = lazy(() => import('./components/Directors'));
const Newsletter = lazy(() => import('./components/NewsletterEmailConfirmation'));
const Contacts = lazy(() => import('./components/Contacts'));

function App() {
    // remove loader when everything is ready
    useEffect(() => {
        const loader = document.getElementById('loader');
        loader.parentElement.removeChild(loader);
    });

    // optional params
    // https://stackoverflow.com/questions/37696391/multiple-params-with-react-router/37918462
    // <BrowserRouter basename = "/public"> this was removed with {{"homepage": "./public/",}} in package.json
    return (
        <BrowserRouter>
            <Error>
                <Template>
                    <Suspense fallback={<LoaderImage />}>
                        <Switch>
                            <Route path='/' exact>
                                <Home />
                            </Route>
                            <Route path='/pages/:name' exact>
                                <Page />
                            </Route>
                            <Route path='/events' exact>
                                <Events />
                            </Route>
                            <Route path='/events/:title/confirm' exact>
                                <EventRegistrationConfirmation />
                            </Route>
                            <Route path='/events/:title/:anchor?'>
                                <Event />
                            </Route>
                            <Route path='/newsletter/confirm'>
                                <Newsletter />
                            </Route>
                            <Route path='/directors'>
                                <Directors />
                            </Route>
                            <Route path='/contacts'>
                                <Contacts />
                            </Route>
                            <Route path='*'>
                                <Home />
                            </Route>
                        </Switch>
                    </Suspense>
                </Template>
            </Error>
        </BrowserRouter>
    );
}

export default App;
