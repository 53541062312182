import React, { useEffect } from 'react';
import { checkSVG } from '../js/browser';
import Logo from './Logo';
import styles from './LoaderImage.module.scss';

export default (props) => {

    useEffect(() => {
        checkSVG();
    });

    return (
        <div
            {...props}
            className={styles.loader}
        >
            <Logo className={styles.logo} />
        </div>
    );
}