// polyfills have been replaced by the 'mdn-polyfills' package in index.js

// check if SVG is supported in the browser
export const checkSVG = () => {
    // https://css-tricks.com/a-complete-guide-to-svg-fallbacks/
    function svgasimg() {
        return document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1");
    }

    if (svgasimg()) {
        let pngImages = document.querySelectorAll('img[data-process="png"]');

        pngImages.forEach(image => {
            let png = image.getAttribute('src');
            let svg = png.replace('.png', '.svg');
            image.setAttribute('src', svg);
            image.removeAttribute('srcset');
        });
    }
};

// Scroll to position
export const scrollToPosition = (position) => {
    window.scrollTo(position.x, position.y);
};


// Scroll to the top of the page
export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

// Scroll to the bottom of the page
export const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
};

// Get element position
// https://www.kirupa.com/html5/get_element_position_using_javascript.htm
export const getPosition = (el) => {
    var xPos = 0;
    var yPos = 0;

    while (el) {
        if (el.tagName === "BODY") {
            // deal with browser quirks with body/window/document and page scroll
            var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
            var yScroll = el.scrollTop || document.documentElement.scrollTop;

            xPos += (el.offsetLeft - xScroll + el.clientLeft);
            yPos += (el.offsetTop - yScroll + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
};