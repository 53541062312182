import React from 'react';
import { root } from '../js/settings';
// import styles from './Logo.module.scss';

export default (props) => {
    return (
        <img className={props.className ? props.className : ""}
            data-process="png"
            srcSet={`${root}/images/british-syrian-society-logo@1.5x.png 1.5x, ${root}/images/$british-syrian-society-logo@2x.png 2x, ${root}/images/$british-syrian-society-logo@3x.png 3x`}
            src={`${root}/images/british-syrian-society-logo.png`}
            alt="The British Syrian Society" />
    );
}