import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { checkSVG } from '../js/browser';
import { APIUrl } from '../js/settings';
import { getCurrentYear } from '../js/dates';
import LoaderImage from './LoaderImage';
import Menu from './Menu';
import Logo from './Logo';
import styles from './Template.module.scss';

// https://github.com/react-ga/react-ga
import ReactGA from 'react-ga';

export default (props) => {

    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const navigation = useRef(null);
    const [fixedNav, setFixedNav] = useState(false);

    //// show logo when the nav bar is at the top
    //useEffect(() => {
    //    const monitorScroll = () => {
    //        navigation.current.getBoundingClientRect().top <= 0 ?
    //            setFixedNav(true) : setFixedNav(false);
    //    };

    //    window.addEventListener("scroll", monitorScroll);

    //    // cleanup: remove the event listener
    //    return () => {
    //        window.removeEventListener("scroll", monitorScroll);
    //    }

    //});

    useEffect(() => {
        // https://github.com/react-ga/react-ga
        ReactGA.initialize('UA-38750467-12');

        // https://medium.com/alturasoluciones/how-to-set-up-and-use-google-analytics-in-react-apps-fb057d195d13
        history.listen((location, action) => {
            ReactGA.pageview(location.pathname + location.search);
        });
    });

    // get all menus on first load
    useEffect(() => {
        if (loading) {
            fetch(`${APIUrl}/menus/all/true`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("Something went wrong, please refresh the page.");
                    }
                })
                .then(menus => {
                    setItems(menus);
                })
                .catch(error => {
                    console.log(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [loading]);

    useEffect(() => {
        checkSVG();
    });

    const renderPage = () => {
        return (
            <>
                <header className={styles.header}>
                    <NavLink
                        to="/"
                    >
                        <Logo className={`${styles.logo} ${fixedNav ? styles.fixed : ""}`} />
                    </NavLink>
                    <NavLink
                        to="/"
                    >
                        <h1>The British Syrian Society</h1>
                    </NavLink>
                </header>
                <nav
                    ref={navigation}
                    className={`${styles.nav} ${fixedNav ? styles.fixed : ""}`}
                >
                    <Menu
                        id="Main Menu"
                        type="responsive"
                        menus={items}
                    />
                </nav>
                <main className={styles.main}>
                    {props.children}
                </main>
                <footer className={styles.footer}>
                    <div>
                        <small>
                            © All rights reserved, The British Syrian Society {getCurrentYear()}.
                        </small>
                        <Menu
                            className={styles.menu}
                            id="Footer Menu"
                            type="simple"
                            menus={items}
                        />
                    </div>
                </footer>
            </>
        );
    };

    return (
        loading ? <LoaderImage /> : renderPage()
    );
}