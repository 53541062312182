import React, { useEffect, useState } from 'react';
import styles from './Menu.module.scss';
import { isMenuItemFromType } from '../js/settings';
import { NavLink } from 'react-router-dom';
import { MdExpandMore as Open, MdMenu as MenuToggle, MdClose as Close } from 'react-icons/md';


export default (props) => {

    const id = props.id;
    const menus = props.menus;
    const currentMenu = menus.find(m => m.title === id);

    const [openedMenus, setOpenedMenus] = useState([]);
    const [menuOpened, setMenuOpened] = useState(false);

    const toggleMainMenu = () => {
        setMenuOpened(!menuOpened);
    };

    const closeAllMenus = () => {
        setOpenedMenus([]);
    };

    const toggleMenu = (e, id) => {
        e.stopPropagation();

        if (openedMenus.includes(id)) {
            setOpenedMenus(openedMenus.filter(m => m !== id));
        } else {
            setOpenedMenus([...openedMenus, id]);
        }
    };

    useEffect(() => {
        // close the opened parent menu when clicking outside
        window.addEventListener("click", closeAllMenus);

        // cleanup: remove the event listener
        return () => {
            window.removeEventListener("click", closeAllMenus);
        }
    });

    const renderMenu = (menu) => {
        return (
            <li
                className={`${openedMenus.includes(menu.itemID) ? styles.opened : ""}`}
                key={menu.itemID}
                onClick={(e) => toggleMenu(e, menu.itemID)}
            >
                <span className={`${styles.link} ${openedMenus.includes(menu.itemID) ? styles.opened : ""}`}>
                    {menu.title}
                    <Open className={`${styles.open} ${openedMenus.includes(menu.itemID) ? styles.flip : ""}`} />
                </span>
                {
                    (
                        openedMenus.includes(menu.itemID)
                    )
                    &&
                    <ul>
                        {
                            menus.map(child =>
                                child.parentMenuID === menu.itemID ?
                                    isMenuItemFromType(child.itemType, "Menu")
                                        ?
                                        renderMenu(child)
                                        :
                                        renderLink(child)
                                    : null
                            )
                        }
                    </ul>
                }
            </li>

        );
    };

    const renderLink = (link) => {
        return (
            <li key={link.itemID}>
                <NavLink
                    to={
                        isMenuItemFromType(link.itemType, "Section") ? link.section : `/pages/${link.pageFriendlyName}`
                    }
                    onClick={toggleMainMenu}
                    className={styles.link}
                    activeClassName={styles.active}
                >
                    {link.title}
                </NavLink>
            </li>
        );
    };

    return (
        <div className={`${styles.nav} ${props.type ? styles[props.type] : styles.simple} ${props.className ? props.className : ""}`}>
            {
                props.type === "responsive"
                &&
                <button
                    className={`${styles.toggle} ${menuOpened ? styles.close : ""}`}
                    onClick={toggleMainMenu}
                >
                    {!menuOpened ? <MenuToggle /> : <Close />}
                </button>
            }

            <ul className={`${styles.root} ${menuOpened ? styles.show : ""}`}>
                {
                    menus.map(item =>
                        item.parentMenuID === currentMenu.itemID
                        &&
                        (
                            isMenuItemFromType(item.itemType, "Menu")
                                ?
                                renderMenu(item)
                                :
                                renderLink(item)
                        )
                    )
                }
            </ul>
        </div>
    );
}