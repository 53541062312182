// Convert date to unix format
export const convertDateToUnix = (date = null, seconds = true) => {
    if (!date) {
        date = new Date();
    } else {
        date = new Date(date);
    }

    // .getTime() uses UTC for time representation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTime
    if (seconds) {
        return Math.floor(date.getTime() / 1000)
    }

    return date.getTime()
}

// Convert date from unix format for date input
export const convertDateFromUnixToString = (unix, slash) => {
    var date = new Date(unix * 1000);

    if (slash) {
        return date.toISOString().slice(0, 10).replace(/-/g, '/');
    }

    return date.toISOString().slice(0, 10);
}

// Convert date from unix format (this will return the UTC date)
export const convertDateFromUnixToDate = (unix) => {
    return new Date((unix * 1000) + (new Date().getTimezoneOffset() * 60 * 1000));
}

// Get current year
export const getCurrentYear = () => {
    return new Date().getFullYear();
}

// Convert unix date to string
export const convertDateFromUnixToLiteralString = (unix) => {
    var date = convertDateFromUnixToDate(unix);
    return `${date.getDate()} ${date.toLocaleString('en-uk', { month: 'long' })} ${date.getFullYear()}`
}